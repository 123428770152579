
import { ref, defineComponent, onMounted} from "vue";
import MessageService from "@/core/services/MessageService";
import { useRoute } from "vue-router";
import JwtService from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {},
  setup() {
    const route = useRoute();
    const token = route.params.token;   
    const store = useStore();
    const router = useRouter(); 
    onMounted(() => {
      if(token!=null)
        setVapidkey();
      if(JwtService.getUsername()?.toString()!=null)
        onSubmitLogin();
    });

    const setVapidkey = () => {
      MessageService.saveVapidKey(token.toString());
    };
    const onSubmitLogin = () => {
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, {email:JwtService.getUsername(), password:JwtService.getPassword()})
          .then(() => {
            router.push({ name: "dashboard" });
          })
      }, 0);
    };
    return {

    };
  },
});
