const ID_VAPID_KEY = "vapid_key" as string;
/**
 * @description get token form localStorage
 */
export const getVapidKey = (): string | null => {
  return window.localStorage.getItem(ID_VAPID_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveVapidKey = (token: string): void => {
  window.localStorage.setItem(ID_VAPID_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyVapidKey = (): void => {
  window.localStorage.removeItem(ID_VAPID_KEY);
};

export default {
  getVapidKey,
  saveVapidKey,
  destroyVapidKey
};
